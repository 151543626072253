@import 'mixins';
@import 'variables';
// @import url('./icons.scss');

html {
  transition: 1s;
  scroll-behavior: smooth;
  font-family: 'ContentFont';
}

.font-primary {
  font-family: 'Koulen';
}

@font-face {
  font-family: 'Koulen';
  src: url(../fonts/Koulen-Regular.ttf);
}

@font-face {
  font-family: 'ContentFont';
  src: url(../fonts/content-font-regular.ttf);
}

.line-clamp-6 {
  line-clamp: 6;
}

.nav-bar-primary-bg {
  background-color: #FFF0F0;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.hero-bg {
  background: url(../images/hero.png);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 70vh;
}

.hero-card-container {
  max-width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: -90px;
}

.hero-card-header {
  border-radius: 30px;
  height: 100px;
  border-radius: 30px;
  color: #FFF0F0;
  padding: 20px;
}

.hero-card-body {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.box-shadow {
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.25);
}

.rounded-30 {
  border-radius: 30px;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.m-t--30 {
  margin-top: -30px;
}

.transition-0-4 {
  transition: 0.4s;
}

.testim-bg-1 {
  z-index: -1;
  background-color: #dfdfdf;
  height: 300px;
  width: 500px;
  border-radius: 10px;
  transform: rotate(-5deg);
}

.testim-bg-2 {
  z-index: -1;
  background-color: #EEE;
  height: 300px;
  width: 500px;
  border-radius: 10px;
  transform: rotate(5deg);
}

.primary-fore-color {
  color: $primary-color;
}

.gradient-bg-1 {
  background: rgb(237,237,237);
  background: linear-gradient(-47deg, rgba(237,237,237,1) 0%, rgba(237,249,255,1) 19%, rgba(216,216,255,1) 47%, rgba(176,221,255,1) 70%, rgba(234,251,255,1) 100%);
  box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
  background-size: 400% 400%;
  animation: GradientBackground 10s ease infinite;
}

.gradient-bg-2 {
  background: rgb(237,237,237);
  background: linear-gradient(84deg, rgb(237, 237, 237) 0%, rgba(237,249,255,1) 19%, rgba(254,254,254,1) 73%, rgba(234,251,255,1) 100%);
  box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
}

.gradient-bg-3 {
  background: rgb(139,254,179);
  background: linear-gradient(0deg, rgba(139,254,179,1) 0%, rgba(145,177,255,1) 100%);
  box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
  background-size: 400% 400%;
  animation: GradientBackground 10s ease infinite;
}

.gradient-bg-4 {
  background: rgb(255,255,255);
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(236,251,255,1) 100%);
  box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
  background-size: 400% 400%;
  animation: GradientBackground 10s ease infinite;
}

.gradient-bg-5 {
  background: rgb(255, 255, 255);
  background: linear-gradient(100deg, rgb(255, 255, 255) 0%, rgb(197, 204, 246) 19%, rgb(234, 223, 223) 73%, rgb(255, 255, 255) 100%);
  box-shadow: -1px 0 15px 5px rgb(228, 246, 255);
  background-size: 400% 400%;
  animation: GradientBackground 5s ease infinite;
}

@keyframes GradientBackground {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
};



